<template>
  <div>
    <vs-form autocomplete="off">
      <div class="custom-form py-4">
        <div class="py-4">
          <h6 style="font-weight:700;">Product details</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.name') && product.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.name')" v-validate="'required'"
              name="name" data-vv-as="Product name" label="Product name" class="custom-input w-full"
              data-vv-scope="basic" :danger-text="errors.first('basic.name')" v-model="product.name" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.productName') && product.productName != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.productName')" name="productName"
              data-vv-as="Dr product name" label="Dr product name" class="custom-input w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.productName')" v-model="product.productName" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Product Visibility</label>
            <div class="flex items-center">
              <vs-switch v-model="product.hidden" />
              <label class="custom-label ml-2">{{ hideStatusText }}</label>
            </div>
            <div class="w-4/5 ml-12 my-2" v-if="!product.hidden">
              <label>Except for clinics</label>
                <v-select
                  :closeOnSelect="false"
                  autocomplete
                  :options="clinics"
                  v-model="product.ifHiddenThenVisibleToClinics"
                  multiple
                  :reduce="clinic => clinic.value"
                ></v-select>
              </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-5">
          <vs-col vs-lg="24" vs-sm="24" vs-xs="24">
            <label class="custom-label">Product Overview</label>
            <vue-ckeditor v-model="product.shortDescription" :config="config" :class="{'productOverviewClass': (invalidProductOverview)}" />
            <!-- <vs-textarea :success="!errors.first('basic.shortDescription') && product.shortDescription != ''"
              val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.shortDescription') ? true : false" name="shortDescription"
              data-vv-as="shortDescription" data-vv-scope="basic" :danger-text="errors.first('basic.shortDescription')"
              v-model="product.shortDescription"
              :class="errors.first('basic.shortDescription') ? 'isError w-full' : 'w-full'" /> -->

            <span v-if="invalidProductOverview" class="span-text-validation custom-error">
              Product Overview is required
            </span>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-5">
          <vs-col vs-lg="24" vs-sm="24" vs-xs="24">
            <label class="custom-label">Product Detail</label>
            <vue-ckeditor v-model="product.description" :config="config" />
            <!-- <vs-textarea :success="!errors.first('basic.description') && product.description != ''"
              val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.description') ? true : false"
              v-validate="'required'" name="description" data-vv-as="Description" data-vv-scope="basic"
              :danger-text="errors.first('basic.description')" v-model="product.description"
              :class="errors.first('basic.description') ? 'isError w-full' : 'w-full'" /> -->
            <span class="span-text-validation custom-error">
              {{ errors.first("basic.description") }}
            </span>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-5">
          <vs-col vs-lg="24" vs-sm="24" vs-xs="24">
            <label class="custom-label">Promotion Banner</label>
            <vue-ckeditor ref="promoBannerRef" v-model="product.subDescription" :config="config" />
            <!-- <vs-textarea :success="!errors.first('basic.description') && product.description != ''"
              val-icon-success="done" val-icon-danger="error" :danger="errors.first('basic.description') ? true : false"
              v-validate="'required'" name="description" data-vv-as="Description" data-vv-scope="basic"
              :danger-text="errors.first('basic.description')" v-model="product.description"
              :class="errors.first('basic.description') ? 'isError w-full' : 'w-full'" /> -->
            <span class="span-text-validation custom-error">
              {{ errors.first("basic.subDescription") }}
            </span>
            <p class="vs-input--label">{{ remainingCharacters }} characters remaining</p>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-5">
          <vs-col vs-lg="24" vs-sm="24" vs-xs="24">
            <label class="custom-label">Promotion Detail</label>
            <vue-ckeditor v-model="product.promotionalDescription" :config="config" />
            <span class="span-text-validation custom-error">
              {{ errors.first("basic.promotionalDescription") }}
            </span>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-4">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">S4 Medication</label>
            <vs-switch v-model="product.s4Medication" />
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <p class="font-bold text-base text-black">Treatment Product</p>
        </div>
        <vs-row vs-align="flex-end" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3 lg:gap-1">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="11">
            <vs-select label="Treatment Product Name" v-model="selectedTreatmentProduct" autocomplete class="w-full">
              <vs-select-item :key="`treatment-product-type-${index}`" :value="item._id" :text="item.name"
                v-for="(item, index) in treatmentProducts" />
            </vs-select>
          </vs-col>
          <vs-col vs-lg="4" vs-sm="4" vs-xs="1" v-if="selectedTreatmentProduct">
            <vs-button color="danger" type="flat" @click="removeTreatmentProduct">
              <vs-icon icon-pack="feather" icon="icon-trash-2" size="20px" class="w-full h-full"></vs-icon>
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :value="selectedTreatmentProductUnit" label="Unit of Measurement" class="w-full mb-3" disabled/>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input v-model="product.amount" label="Amount" type="number" class="w-full" v-if="!selectedTreatmentProduct" />
            <vs-input :success="!errors.first('basic.treatmentAmount')" type="number" v-else
              style="margin-bottom: 0;" v-validate="'required'"
              val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.treatmentAmount')" name="treatmentAmount" data-vv-as="Treatment Amount"
              label="Amount" class="custom-input w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.treatmentAmount')" v-model="product.amount" />
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Product Type</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-select v-model="selectedProductType" autocomplete>
              <vs-select-item :key="`product-type-${index}`" :value="item.value" :text="item.text"
                v-for="(item, index) in products" />
            </vs-select>
          </vs-col>
        </vs-row>
        <LeadFormProduct v-if="selectedProductType === 'lead'" :product="product" @updateProductType="product = $event">
        </LeadFormProduct>
        <div v-if="selectedProductType === 'script'" class="mt-5 w-1/3">
          <label>Direction for use:</label>
          <vs-textarea v-model="product.directionForUse" />
        </div>
        <BundledItems v-if="selectedProductType === 'bundled-items'" :bundledItems="bundledItems" @newBundleItems="handleAddBundleItems" :productDetails="product" @updateProductType="product = $event">
        </BundledItems>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Images</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label id="imagesId" :class="{ 'six-pixel': errors.has('hasSelectedImages') }"
              class="image-upload images-upload">
              Upload images
              <input :key="filekey" type="file" ref="inputFile" multiple accept="image/*"
                @change="uploadImage($event, 'imagesUpload', 'images')" id="file-input" hidden />
            </label>
          </vs-col>
        </vs-row>
        <div class="con-example-images mt-5">
          <ul class="product-images">
            <li v-for="(image, index) in product.images" v-bind:key="index"
              :style="'background: url(' + image.image + ');background-size:cover;'"
              class="inline-block mr-4 product-store-image relative mb-3" @mouseover="hover = true"
              @mouseleave="hover = false">
              <x-circle-icon size="2x" class="custom-class" @click="removeProductImage(image)"></x-circle-icon>
              <div v-if="hover" class="absolute bottom-0 p-1 w-full" style="background-color: rgb(229 231 235);">
                <vs-radio :vs-value="true" vs-name="radios2" v-model="image.useAsDefault"
                  @change="changeDefaultImage(image.image, image._id)">Use As Default</vs-radio>
              </div>

            </li>
          </ul>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Documents</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label id="imagesId" :class="{ 'six-pixel': errors.has('hasSelectedImages') }"
              class="image-upload images-upload">
              Upload documents
              <input :key="filekey" type="file" ref="inputFile" multiple accept="application/pdf"
                @change="uploadImage($event, 'imagesUpload', 'documents')" id="file-input" hidden />
            </label>
            <p class="mt-5" v-show="!product.documents.length">Upload a document to make the documents section visible
              on the product detail page.</p>
          </vs-col>
        </vs-row>
        <div class="con-example-images mt-5">
          <ul class="product-images">
            <li v-for="(image, index) in product.documents" :key="index" :style="'background-size:cover;'"
              class="inline-block mr-4 product-store-image relative mb-4">
              <x-circle-icon size="2x" class="custom-class" @click="removeProductImage(image, 'documents')">
              </x-circle-icon>
              <a class="pdf-link" :href="image.image" target="_blank">PDF</a>
              <span class="pdf-filename absolute truncate ">{{ image.fileName }}</span>
              <vs-button v-if="image._id" @click="openFileRenameModal(image._id)" position="bottom" class="pdf-rename w-full"
                color="primary" type="flat">Rename</vs-button>
            </li>
          </ul>
          <vs-prompt title="Rename File Name" :active.sync="activePrompt" accept-text="Save">
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <vs-col>
                <vs-input v-model="updatedFileName" @change="updateFileName(pdfSelectedId)" class="w-full" />
              </vs-col>
            </vs-row>
          </vs-prompt>
        </div>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Tags</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" />
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Pricing</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.price') && product.price != ''" val-icon-success="done"
            style="margin-bottom: 0;"
              val-icon-danger="error" :danger="errors.first('basic.price')" v-validate="'required'"
              name="price" data-vv-as="Price" label="Price" class="custom-input w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.price')" v-model="product.price" />
          </vs-col>
          <vs-row class=" text-sm mb-5">
            <span v-if="product.priceHistory.nursePrice">
              Last updated by {{ product.priceHistory.nursePrice.name }} from ${{ product.priceHistory.nursePrice.priceFrom | formatDecimals}} to ${{ product.priceHistory.nursePrice.priceTo | formatDecimals}} on {{ formatDate(product.priceHistory.nursePrice.createdAt) }} at {{ formatTIme(product.priceHistory.nursePrice.createdAt) }}
            </span>
          </vs-row>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.supplierCostPrice') && product.supplierCostPrice != ''" val-icon-success="done"
            style="margin-bottom: 0;"
              val-icon-danger="error" :danger="errors.first('basic.supplierCostPrice') ? true : false" v-validate="'required'"
              name="supplierCostPrice" data-vv-as="Cost Price" label="Cost Price" class="custom-input w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.supplierCostPrice')" v-model="product.supplierCostPrice" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.productPrice') && product.productPrice != ''"
              style="margin-bottom: 0;"
              val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.productPrice')" name="productPrice" data-vv-as="Dr Price"
              label="Dr Price" class="custom-input w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.productPrice')" v-model="product.productPrice" />
          </vs-col>
            <vs-row class=" text-sm mb-5">
              <span v-if="product.priceHistory.drPrice">
                Last updated by {{ product.priceHistory.drPrice.name }} from ${{ product.priceHistory.drPrice.priceFrom | formatDecimals}} to ${{ product.priceHistory.drPrice.priceTo | formatDecimals}} on {{ formatDate(product.priceHistory.drPrice.createdAt) }} at {{ formatTIme(product.priceHistory.drPrice.createdAt) }}
              </span>
            </vs-row>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input label="Key Account Price (For Evolus Supplier)" class="w-full" v-model="product.KAPrice" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.compareAtPrice') && product.compareAtPrice != ''"
              val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.compareAtPrice')" name="compareAtPrice"
              data-vv-as="Compare at price" label="RRP (optional)" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.compareAtPrice')" v-model="product.compareAtPrice" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col>
            <div class="flex items-center justify-left gap-3 w-full">
            <vs-checkbox v-model="product.isCompareAtPriceToggled" />
            <span>
              <div class="text-sm">[x] &lt;-- Strikethrough applied</div>
              <div class="text-sm">[  &nbsp;] &lt;-- No strikethrough applied</div>
            </span>
          </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-5">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Hide RRP</label>
            <vs-switch v-model="product.isRRPHidden" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.listPrice') && product.listPrice != ''"
              val-icon-success="done" val-icon-danger="error"
              :danger="errors.first('basic.listPrice')" name="listPrice"
              data-vv-as="List Price" label="List Price (optional)" class="w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.listPrice')" v-model="product.listPrice" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col>
            <div class="flex items-center justify-left gap-3">
            <vs-checkbox v-model="product.isListPriceToggled" />
            <span>
              <div class="text-sm">[x] &lt;-- Strikethrough applied</div>
              <div class="text-sm">[  &nbsp;] &lt;-- No strikethrough applied</div>
            </span>
          </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="center" vs-type="flex" vs-justify="left" vs-w="12" class="mb-5">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Hide List Price</label>
            <vs-switch v-model="product.isListPriceHidden" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-5">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Taxable</label>
            <vs-switch v-model="product.isTaxable" />
          </vs-col>
        </vs-row>
        <!-- Price per measurement -->
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <h6 style="font-weight:500;">Price per measurement</h6>
            <div class="flex justify-between">
              <label class="custom-label pr-5">
                Add a price per measurement to a product tile.
                The calculation is based off the clinics default price for this product.
                If a clinic has special price the calculation will adjust to their adjusted pricing.
              </label>
              <vs-switch v-model="product.isPricePerMeasurementHidden" />
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input
              style="margin-bottom: 0;"
              placeholder="0"
              label="What number should the dynamic price be divided by?"
              class="custom-input w-full"
              v-model="product.pricePerMeasurement.price" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input
              style="margin-bottom: 0;"
              label="What text will display on the product card after the dollar value?"
              placeholder=" / 1mL"
              class="custom-input w-full"
              v-model="product.pricePerMeasurement.unit" />
          </vs-col>
        </vs-row>
        <!-- end of Price per measurement -->
        <!-- SKU supplier -->
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mb-3">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input
              style="margin-bottom: 0;"
              label="Supplier SKU"
              class="custom-input w-full"
              v-model="product.supplierSKU" />
          </vs-col>
        </vs-row>
        <!-- end of SKU supplier -->
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form pt-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Inventory</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5">
            <label class="custom-label">Supplier</label>
            <vs-select v-model="product.inventory.managedBy" class="w-full" autocomplete
              name="managedBy" val-icon-success="done" :danger="errors.first('basic.managedBy')"
              :danger-text="errors.first('basic.managedBy') ? 'Supplier is required' : ''"
              :success="!errors.first('basic.managedBy') && product.inventory.managedBy != ''"
              data-vv-scope="basic" val-icon-danger="error" v-validate="'required'">
              <vs-select-item :key="index" :value="item.value" :text="item.label"
                v-for="(item, index) in suppliers" />
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.sku') && product.inventory.sku != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.sku')" name="sku" data-vv-as="SKU"
              label="SKU" class="custom-input w-full" data-vv-scope="basic" :danger-text="errors.first('basic.sku')"
              v-model="product.inventory.sku" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <div class="sageButton" ref="sageButton">
                <vs-input state="success"  name="sageItemId" data-vv-as="Sage Item id" label="Sage Item Id"
                class="custom-input w-full" data-vv-scope="basic" :danger-text="sageError"
                val-icon-success="done"
                loading
                val-icon-danger="error"
                :success-text="sageSuccess"
                :success="sageSuccess ? true : false"
                v-model="product.sageItemId" 
                @input="validateSageItem"
                :danger="sageError ? true : false" />
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Out of stock</label>
            <vs-switch v-model="outOfStock" />
          </vs-col>
        </vs-row>
        <vs-row class=" text-sm mb-5">
            <span v-if="product.stockHistory">
              Last updated by {{ product.stockHistory.name }} from {{ product.stockHistory.from ? 'true' : 'false'}} to {{ product.stockHistory.to ? 'true' : 'false'}} on {{ formatDate(product.stockHistory.createdAt) }} at {{ formatTIme(product.stockHistory.createdAt) }}
            </span>
          </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Reporting Category</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-select
              v-model="product.primaryCategoryId"
              class="w-full"
              autocomplete
              name="primaryCategoryId"
              val-icon-success="done"
              val-icon-danger="error">
              <vs-select-item :key="index" :value="item.value" :text="item.label"
                v-for="(item, index) in primaryCategories" />
            </vs-select>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Categories</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <div class="section-wrapper w-full">
              <div class="category-main-section py-5 px-3" style="border: solid 1px #dadada;border-radius:5px;">
                <div class="parent-category" v-for="(item, index) in categories" :key="index">
                  <div class="non-accordion">
                    <feather-icon v-if="
                      item.ancestors &&
                      item.ancestors.length > 0 &&
                      showTickArray.indexOf(`parent-categor-${index}`) < 0
                    " icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1 accordion-icon"
                      @click="showHideTick(`parent-categor-${index}`)" />

                    <feather-icon v-else-if="
                      item.ancestors &&
                      item.ancestors.length > 0 &&
                      showTickArray.indexOf(`parent-categor-${index}`) > -1
                    " icon="ChevronUpIcon" svgClasses="h-4 w-4" class="ml-1 accordion-icon"
                      @click="showHideTick(`parent-categor-${index}`)" />

                    <vs-checkbox :value="product.categoryId.indexOf(item._id) > -1"
                      @change="categoryClicked(item._id, $event)" class="mt-1 space" style="width:220px">
                      <label style="">{{ item.name }} </label>
                    </vs-checkbox>
                  </div>

                  <div class="child-category" style="margin-left: 3rem" v-if="
                    item.ancestors &&
                    item.ancestors.length > 0 &&
                    showTickArray.indexOf(`parent-categor-${index}`) > -1
                  ">
                    <div class="child" v-for="(ancestorItem, ancestorsIndex) in item.ancestors" :key="ancestorsIndex">
                      <div :class="
                        !ancestorItem.grandAncestors ||
                          ancestorItem.grandAncestors.length === 0
                          ? 'non-accordion'
                          : 'mb-5'
                      ">
                        <vs-checkbox :value="product.categoryId.indexOf(ancestorItem._id) > -1"
                          @change="categoryClicked(ancestorItem._id, $event)" class="mt-1 space">
                          <label>{{ ancestorItem.name }} </label>
                        </vs-checkbox>
                      </div>

                      <div class="grand-child-category" style="margin-left: 3rem" v-if="
                        ancestorItem.grandAncestors &&
                        ancestorItem.grandAncestors.length > 0
                      ">
                        <div class="mb-5" v-for="(
                        grandAncestorItem, grandAncestorsIndex
                      ) in ancestorItem.grandAncestors" :key="grandAncestorsIndex">
                          <div style="display: inline-block">
                            <vs-checkbox :value="product.categoryId.indexOf(grandAncestorItem._id) > -1"
                              @change="
                                categoryClicked(grandAncestorItem._id, $event)
                              " class="mt-1 space">
                              <label>{{ grandAncestorItem.name }} </label>
                            </vs-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Brand</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5">
            <vs-select 
              v-model="product.brandId" 
              class="w-full" 
              autocomplete 
              name="brand"
              val-icon-success="done"
              :danger="errors.first('basic.brand')"
              :danger-text="errors.first('basic.brand')"
              :success="!errors.first('basic.brand') && product.brand != ''"
              data-vv-scope="basic"
              val-icon-danger="error" 
              v-validate="'required'"
            >
              <vs-select-item :key="index" :value="item.value" :text="item.label"
                v-for="(item, index) in brands" />
            </vs-select>
          </vs-col>
        </vs-row>
      </div>
      <vs-divider></vs-divider>
      <div class="custom-form py-4">
        <div class="pb-4">
          <h6 style="font-weight:700;">Settings</h6>
        </div>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Custom Delivery Fee</label>
            <p class="mb-4">Toggle to enable/disable custom delivery fee.</p>
            <vs-switch v-model="product.isCustomDeliveryFee" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-2">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input
              :disabled="!product.isCustomDeliveryFee"
              class="custom-input w-full"
              v-model="product.customDeliveryFee" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.minimumPurchase') && product.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.minimumPurchase')"
              v-validate="'required'" name="minimumPurchase" data-vv-as="Minimum purchase qty"
              label="Minimum purchase qty" class="custom-input w-full" data-vv-scope="basic"
              :danger-text="errors.first('basic.minimumPurchase')" v-model="product.inventory.requiredQuantity"
              @input="filterNumbers('requiredQuantity')" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-input :success="!errors.first('basic.maximumPurchase') && product.name != ''" val-icon-success="done"
              val-icon-danger="error" :danger="errors.first('basic.maximumPurchase')"
              name="maximumPurchase" data-vv-as="Maximum purchase qty" label="Maximum purchase qty"
              class="custom-input w-full" data-vv-scope="basic" :danger-text="errors.first('basic.maximumPurchase')"
              v-model="product.inventory.maxQuantity" @input="filterNumbers('maxQuantity')"/>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5">
            <label class="custom-label">Product Type</label>
            <vs-select v-model="product.productType" class="w-full" autocomplete
              name="productType" val-icon-success="done" :danger="errors.first('basic.productType')"
              :danger-text="errors.first('basic.productType') ? 'Product type is required' : ''"
              :success="!errors.first('basic.productType') && product.productType != ''"
              data-vv-scope="basic" val-icon-danger="error" v-validate="'required'">
              <vs-select-item :key="index" :value="item.value" :text="item.label"
                v-for="(item, index) in types" />
            </vs-select>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5">
            <label class="custom-label">Visible To</label>
            <vs-select v-model="product.visibleTo" class="w-full" autocomplete
              name="visibleTo" val-icon-success="done" :danger="errors.first('basic.visibleTo')"
              :danger-text="errors.first('basic.visibleTo') ? 'Visible to is required' : ''"
              :success="!errors.first('basic.visibleTo') && product.visibleTo != ''"
              data-vv-scope="basic" val-icon-danger="error" v-validate="'required'">
              <vs-select-item v-bind:key="index" :value="item.value" :text="item.label"
                v-for="(item, index) in roles" />
            </vs-select>
          </vs-col>
        </vs-row>
        <!-- <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">Scripting product</label>
            <p class="mb-4">This will add in scripting details on to the product detail page.</p>
            <vs-switch v-model="product.isScript" />
          </vs-col>
        </vs-row> -->
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-4">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label class="custom-label">T+C</label>
            <p class="mb-4">This will add a t+c section to the product detail page.</p>
            <vs-switch v-model="product.tc" />
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-4" v-if="showTCLink">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <a :href="product.tcLink" target="_blank" class="inline-block p-16"
              :style="'background:url(' + product.tcLink + ');background-size:cover;'" v-if="isImage">
            </a>
            <a :href="product.tcLink" target="_blank" class="flex justify-center items-center border"
              style="height:100px;width:100px;border: solid 1px #a0a0a0;" v-if="!isImage">
              PDF
            </a>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-4" v-if="showTCLink">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label :class="{ 'six-pixel': errors.has('hasSelectedImages') }" class="replace-image">
              Upload new image
              <input type="file" accept="image/*,application/pdf" @change="uploadImage($event, 'imagesUpload', 'image')"
                id="file-input" hidden />
            </label>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-4"
          v-if="!showTCLink && showTC">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <label id="imagesId" :class="{ 'six-pixel': errors.has('hasSelectedImages') }"
              class="image-upload images-upload">
              Upload T+C file
              <input type="file" accept="image/*,application/pdf" @change="uploadImage($event, 'imagesUpload', 'image')"
                id="file-input" hidden />
            </label>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-1"
          v-if="!showTCLink && showTC">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <small>Image files accepted. Exp PDF, Jpg</small>
          </vs-col>
        </vs-row>
        <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="mt-6">
          <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
            <vs-button color="danger" style="padding:5px 15px;" @click="onShowDeleteModal" :disabled="isDeleteDisabled">
              Delete</vs-button>
          </vs-col>
        </vs-row>
        <vs-prompt
          title="Delete Confirmation"
          :active.sync="showDeleteModal"
          @accept="onDeleteProduct"
          accept-text="Delete">
          <div>
            Are you sure you want to delete {{ product.name }} ?
          </div>
        </vs-prompt>
      </div>
    </vs-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from "@/axios";
import { XCircleIcon } from 'vue-feather-icons'
import VueCkeditor from 'vue-ckeditor2';
import VueTagsInput from '@johmun/vue-tags-input';
import LeadFormProduct from './product-type/LeadFormProduct.vue';
import BundleProduct from './product-type/BundleProduct.vue';
import BundledItems from './product-type/BundledItems.vue'
import moment from 'moment';
import vSelect from "vue-select";
import StoreHelper from '../../../../mixins/Store.vue';

export default {
  name: "ProductDetails",
  mixins: [StoreHelper],
  props: ["product", "save", "createOrUpdateSupplier", "bundledItems", "newBundleItems"],
  components: {
    XCircleIcon,
    VueCkeditor,
    VueTagsInput,
    LeadFormProduct,
    BundleProduct,
    BundledItems,
    vSelect
  },
  data() {
    return {
      selectedTreatmentProduct: null,
      treatmentProducts: [],
      selectedProductType: "",
      sageError:'',
      sageSuccess:'',
      timeout:'',
      products: [],
      outOfStock: false,
      suppliers: [],
      types: [{ label: "Goods", value: "Goods" }, { label: "Training", value: "Training" }],
      assignedSupplier: null,
      assignedVisibleTo: "All",
      categories: [],
      primaryCategories: [],
      secondaryCategories: [],
      showTickArray: [],
      selectedSecondaryCategories: [],
      visibilities: [],
      roles: [],
      filekey: 0,
      managedBy: null,
      tag: '',
      tags: [],
      config: {
        toolbar: [
          {
            name: 'clipboard',
            items: ['Undo', 'Redo']
          },
          { name: 'styles', items: ['Format'] },
          {
            name: 'basicstyles',
            items: [
              'Bold',
              'Italic',
              'Strike',
            ]
          },
          {
            name: 'pagragraph',
            items: [
              'NumberedList',
              'BulletedList',
            ]
          },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
        ],
        versionCheck: false,
        height: 300
      },
      hover: false,
      isDeleteDisabled: false,
      updatedFileName: '',
      pdfSelectedId: '',
      activePrompt: false,
      showDeleteModal: false,
      brands: [],
      clinics: [],
      initialLoad: true,
      maxCharacters: 50,
      editorInstance: null,
      invalidProductOverview: false,
    }
  },
  methods: {
    ...mapActions("storeProducts", [
      "fetchCategories",
      "fetchPrimaryCategories",
      "fetchSecondaryCategories",
      "fetchSuppliers",
      "storeProduct",
      "addMoreProductImage",
      "deleteProduct",
      "fetchProductTypes",
      "deleteBundleItems",
    ]),
    ...mapActions("product", ["fetchBrands", "fetchProducts"]),
    ...mapActions("rolesAndPermissions", ["getAllRoles"]),
    ...mapActions("clinic", ["fetchAllClinic"]),
    removeTreatmentProduct() {
      this.selectedTreatmentProduct = null;
      this.product.amount = null;
    },
    handleAddBundleItems(product){
      this.$emit('newBundleItems', product);
    },
    async getTreatmentProducts() {
      const payload = {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 2000
      }
      const res = await this.fetchProducts(payload);
      if (res.status === 200) {
        this.treatmentProducts = res.data.data.docs;
      }
    },
    formatDate(date) {
      return moment(date).format('MMM Do YYYY');
    },
    formatTIme(date) {
      return moment(date).format('LT');
    },
    async validateSageItem() {
        if (this.timeout) 
            clearTimeout(this.timeout); 

        this.timeout = setTimeout(async () => {
            this.getSageItemInfo()
        }, 1000); // delay
    },
    async getSageItemInfo() {
        try {
            if (!this.product.sageItemId) {
                this.sageSuccess = ''
                this.sageError = ''
                return
            }

            this.$vs.loading();

            const {data} = await axios.get(`/api/v1/store/products/sageDetails/${this.product.sageItemId}`)
            
            if (data && data.data && data.data.NAME) {
                this.sageSuccess = `Product ID ${this.product.sageItemId} corresponds to an entity named ${data ? data.data.NAME : ''} on the Sage platform.`
                this.sageError = ''
            } else {
                this.sageSuccess = ''
                this.sageError = `The product with ID ${this.product.sageItemId} does not exist on the Sage platform.`
            }
        } catch {
            this.sageSuccess = ''
            this.sageError = `The product with ID ${this.product.sageItemId} does not exist on the Sage platform.`
        } finally {
            this.$vs.loading.close();
        }
    },
    getSuppliers() {
      this.fetchSuppliers().then(res => {
        this.suppliers = res.data.data.map(({ _id: value, name: label, isEvolusSupplier }) => ({
          label, value, isEvolusSupplier
        }));
        return this.fetchCategories();
      }).then(res => {
        this.categories = res.data.data
        return this.getAllRoles();
      }).then(rules => {

        this.roles = rules.data.data.map(({ _id: value, role: label }) => ({ label, value: value }))
        this.roles.unshift({
          value: "All",
          label: "All",
        });

        return this.fetchBrands()
      }).then(brands => {
        this.brands = brands.map(({ _id: value, brandName: label }) => ({ label, value: value })).sort((a, b) => {
          // Added sort to place 'No brand' at the top of the list
          if (!this.excludedBrand) return 0;
          if (a.label.toLowerCase() === this.excludedBrand.toLowerCase()) return -1;
          if (b.label.toLowerCase() === this.excludedBrand.toLowerCase()) return 1;
          return 0;
        })
      }).catch(err => {
        console.error(err)
      })
    },
    categoryClicked(val, event, key = 'categoryId') {
      if (event.target.checked) {
        const index = this.product[key].indexOf(val);
        if (index < 0) this.product[key].push(val);
      } else {
        const index = this.product[key].indexOf(val);
        if (index > -1) {
          this.product[key].splice(index, 1);
        }
      }
    },
    showHideTick(id, key = 'showTickArray') {
      if (this[key].indexOf(id) < 0) this[key].push(id);
      else this[key].splice(this[key].indexOf(id), 1);
    },
    validateBundleItems() {
      if (!["bundle", "bundled-items"].includes(this.selectedProductType)) return true;
      if (!this.bundledItems.length) {
        this.$vs.notify({
          title: "Bundle Item Error",
          text: "Please add at least one item to the bundle.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      const hasNotFreeItems = this.bundledItems.some((item) => !item.isFree);
      if (!hasNotFreeItems) {
        this.$vs.notify({
          title: "Bundle Item Error",
          text: "Please add at least one item that is not free.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      return true;
    },
    async saveData() {
      this.showVariantError = false;

      let isValid = await this.$validator.validate();

      let submit = true;

      if (isValid && submit && this.validateProductOverview() && this.validateBundleItems()) {
        this.product.primaryCategoryId = !this.product.primaryCategoryId ? null : this.product.primaryCategoryId;

        if (this.product.images.length) {
          if (this.product.productType != "Goods") {
            this.product.manufacturedDate = null;
            this.product.expiryDate = null;
          }

          const newProduct = { ...this.product };
          if (
            newProduct.inventory.sku === null ||
            newProduct.inventory.sku === ""
          ) {
            newProduct.inventory.sku = null;
          }

          // Treatment Products
          newProduct.treatmentProduct = this.selectedTreatmentProduct;
          newProduct.unit = this.selectedTreatmentProductUnit;

          newProduct.isAvailable = !this.outOfStock;
          newProduct.tags = this.tags;
          if (newProduct.type !== "bundle") {
            newProduct.type = this.selectedProductType;
          }
          newProduct.hideStatus = !this.product.hidden
          delete newProduct.history;

          if (newProduct.type === "bundle" && this.bundledItems.length) {
            newProduct.options = this.bundledItems.map((item) => {
              return {
                value: item.name,
                isFree: item.isFree,
                supplierCostPrice: item.supplierCostPrice,
                productStoreId: item.productId,
                ...(item.isFree && { quantity: Number(item.quantity) || 1}),
                KAPrice: item.KAPrice,
                type: item.type,
                supplierId: item.supplierId,
                sageItemId: item.sageItemId,
              }
            })
          }
          this.$emit("createOrUpdateSupplier", newProduct);
        } else {
          this.$vs.notify({
            title: "Image is required",
            text: "Please upload at least one image.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }

      }
    },
    removeProductImage(image, type = "images") {
      if (this.product._id) {
        axios
          .get(
            `/api/v1/store/products/deleteImage?imageId=${image._id}&imageUrl=${image.image}&productId=${this.product._id}&type=${type}`
          )
          .then((res) => {
            this.product[type] = this.product[type].filter(
              (value) => value._id != image._id
            );
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Removing Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        this.product[type] = this.product[type].filter(
          (value) => value.image != image.image
        );
        this.$vs.loading.close();
      }
    },
    changeDefaultImage(imgUrl, imgId = null) {
      let tempArray = [];
      this.product.images.forEach((datum) => {
        if (datum.image === imgUrl) datum.useAsDefault = true;
        else datum.useAsDefault = false;
        tempArray.push(datum);
      });

      this.product.images = tempArray;
      this.hasMakeImageDefault = true;

      if (imgId) {
        const params = {
          id: this.productId,
          imageId: imgId,
        };
        this.updateProductImageStatus(params).then((res) => console.log(res));
      }
    },
    openFileRenameModal(id) {
      this.product.documents.forEach((datum) => {
        if (datum._id === id) {
          this.activePrompt = true
          this.updatedFileName = datum.fileName
        }
      });
      this.pdfSelectedId = id
    },
    updateFileName(id) {
      this.product.documents.forEach((datum) => {
        if (datum._id === id) {
          datum.fileName = this.updatedFileName
        }
      });
    },
    async uploadImage(event, key = "profileImage", type = "images", index = null, isSingle = false) {
      let data = new FormData();
      [...event.target.files].map(val => {
        data.append("profileImage", val)
      })
      const pdfFileName = [...event.target.files].map(val => val.name)
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      this.$vs.loading();
      const newImages = [];
      axios
        .post(`/api/v1/store/products/uploadImages?key=` + key, data, config)
        .then((res) => {
          if (type === "images") {
            let useAsDefault = this.product.images.length <= 0;
            res.data.data.forEach((datum) => {
              newImages.push(datum);
              if (!this.product._id) {
                this.product[type].push({
                  image: datum.Location,
                  useAsDefault,
                });
              }
              useAsDefault = false;
            });
            this.hasSelectedImages = true;
            this.filekey++;
            if (this.product._id) {

              this.addImagesToProduct(newImages);
            }
          } else if (type === "documents") {
            res.data.data.forEach((datum, index) => {
              newImages.push({
                ...datum,
                fileName: pdfFileName[index]
              });

              // if (!this.product._id) {
              this.product[type].push({
                image: datum.Location,
                useAsDefault: false,
                fileName: newImages[index].fileName
              });
              // }
            });
            this.filekey++;
            if (this.product._id) {
              this.addImagesToProduct(newImages, "documents");
            }
          } else {
            this.product.tcLink = res.data.data[0].Location
          }
          this.$vs.loading.close();

        })
        .catch((err) => {
          console.error(err, 'error value')
          this.$vs.loading.close();
          this.filekey++;
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: err.data.message
                ? err.data.message
                : "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    addImagesToProduct(data, type = 'images') {
      let images = [];

      data.forEach((datum) => {
        images.push({
          image: datum.Location,
          useAsDefault: false,
          fileName: type !== 'images' ? datum.fileName : ''
        });
      });

      const params = {
        id: this.product._id,
        images,
        type
      };

      this.addMoreProductImage(params)
        .then((success) => {
          this.product[type] = success.data.data[type];
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    onShowDeleteModal () {
      this.showDeleteModal = true
    },
    onDeleteProduct() {
      this.showDeleteModal = false

      this.$vs.loading();
      this.isDeleteDisabled = true
      const self = this;

      this.deleteProduct(this.product._id).then(async (success) => {
        await this.deleteBundleItems({ parentProductId: this.product._id})
        self.$vs.notify({
          title: "Product Deleted",
          text: "Product deleted successfully",
          color: "success",
        });
        self.$vs.loading.close();
        this.$router.push('/super-admin/store/products')
      }).catch(err => {
        console.error(err);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: err.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }).finally(() => self.isDeleteDisabled = false)
    },
    async getProductTypes() {
      try {
        const res = await this.fetchProductTypes();
        if (res.status == 200) {
          this.products = res.data.data;
        }
      } catch (error) {
        console.error("ERROR: ", error);
      }
    },
    filterNumbers(field) {
      // Filter out non-digit characters
      this.product.inventory[field] = this.product.inventory[field].replace(/\D/g, '');

      if (!this.product.inventory[field]) {
        this.product.inventory.maxQuantity = '';
        return;
      }

      // Ensure maxQuantity is greater than or equal to requiredQuantity
      if (field === 'maxQuantity') {
        const max = parseInt(this.product.inventory.maxQuantity, 10) || 0;
        const min = parseInt(this.product.inventory.requiredQuantity, 10) || 0;

        if (max < min) {
          this.product.inventory.maxQuantity = min;
        }
      }
    },
    decodeHtmlEntities(text) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = text;
      return textarea.value; // Decodes entities like &nbsp;
    },
    stripHtmlTags(html) {
      // Remove HTML tags to get the visible text
      return html.replace(/<[^>]*>/g, "").trim();
    },
    calculateContentLength(editorContent) {
      // Decode the stripped content and count its length
      const visibleContent = this.decodeHtmlEntities(this.stripHtmlTags(editorContent));
      return visibleContent.length;
    },
    handleEditorReady(editor) {
      this.editorInstance = editor;

      // Listen to the 'key' event to prevent input when limit is reached
      editor.on("key", (event) => {
        const keyCode = event.data.keyCode;
        const ctrlKey = event.data.domEvent.$.ctrlKey || event.data.domEvent.$.metaKey; // Handle Ctrl or Cmd (macOS)

        // Allow navigation and shortcut keys (Ctrl/Command + Z, Ctrl/Command + C, etc.)
        const allowedKeys = [
          8, // Backspace
          46, // Delete
          37, // Left Arrow
          39, // Right Arrow
          38, // Up Arrow
          40, // Down Arrow
          17, // Ctrl
          35, // End
          36, // Home
        ];

        // Allow navigation and shortcuts
        if (allowedKeys.includes(keyCode) || ctrlKey) {
          return;
        }

        // Check content length
        const contentLength = this.calculateContentLength(editor.getData());
        const selection = editor.getSelection();
        const selectedText = selection && selection.getSelectedText();
        const selectedLength = selectedText ? selectedText.length : 0;

        // Block input if it exceeds the limit
        if (contentLength - selectedLength + 1 > this.maxCharacters) {
          event.cancel(); // Prevent character input
        }
      });

      // Handle paste events
      editor.on("paste", (event) => {
        event.cancel(); // Stop default paste behavior

        const clipboardData = event.data.dataValue || "";
        const contentLength = this.calculateContentLength(editor.getData());
        const remainingCharacters = this.maxCharacters - contentLength;

        if (remainingCharacters > 0) {
          const strippedPasteData = this.decodeHtmlEntities(this.stripHtmlTags(clipboardData));
          const contentToInsert = strippedPasteData.substring(0, remainingCharacters);

          // Insert the trimmed content into the editor
          if (contentToInsert) {
            editor.insertHtml(contentToInsert);
          }
        }
      });
    },
    validateProductOverview() {
      if (!this.product.shortDescription) {
        this.invalidProductOverview = true;
        return false;
      }

      this.invalidProductOverview = false;
      return true;
    },
    async getPrimaryCategories() {
      try {
        const res = await this.fetchPrimaryCategories()

        this.primaryCategories = res.data.data.map(({ _id: value, name: label }) => ({ value, label }))
      } catch (error) {
        console.error("ERROR fetching primary categories: ", error);
      }
    },
    async getSecondaryCategories() {
      try {
        const res = await this.fetchSecondaryCategories()

        this.secondaryCategories = res.data.data
      } catch (error) {
        console.error("ERROR fetching secondary categories: ", error);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const editor = this.$refs.promoBannerRef.instance;
      this.editorInstance = editor;

      this.handleEditorReady(editor);
    });
  },
  async created() {
    this.selectedProductType = this.product.type !== 'bundle' ? this.product.type : 'bundled-items';

    await this.getPrimaryCategories()
    await this.getSecondaryCategories()

    await this.fetchAllClinic()
      .then((res) => {
        this.clinics = res.data.data.map(
          ({ _id: value, clinicName: label }) => ({
            label,
            value,
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
    this.getSuppliers();
    this.getTreatmentProducts();
    this.getProductTypes();
  },
  computed: {
    remainingCharacters() {
      const contentLength = this.calculateContentLength(this.product.subDescription || "");
      return Math.max(0, this.maxCharacters - contentLength);
    },
    hideStatusText() {
      return this.product.hidden ? "product is visible" : "product is hidden"
    },
    selectedTreatmentProductUnit() {
      if (!this.treatmentProducts.length || !this.selectedTreatmentProduct) return null;
      return this.treatmentProducts.filter(e => e._id === this.selectedTreatmentProduct)[0].unit
    },
    changeData() {
      return `${this.categories} | ${this.product}`
    },
    changeSecondaryCategoriesData() {
      return `${this.secondaryCategories} | ${this.product}`
    },
    changeSupplier() {
      return `${this.suppliers} | ${this.product} | ${this.roles}`
    },
    showTCLink() {
      return this.product.tcLink && this.product.tcLink.length && this.product.tc
    },
    showTC() {
      return this.product.tc
    },
    isImage() {
      const extension = this.product.tcLink.split(".");
      return extension[extension.length - 1] !== 'pdf'
    }
  },
  watch: {
    "product.shortDescription": {
      handler(newVal) {
        if (newVal) {
          this.invalidProductOverview = false;
        }
      },
    },
    "save": function (save) {
      if (save) {
        this.saveData()
      }
    },
    "product": {
      handler(newVal) {
        if (this.initialLoad) {
          this.tags = newVal.tags;
          this.selectedTreatmentProduct = newVal.treatmentProduct && newVal.treatmentProduct.length ? newVal.treatmentProduct[0].productId : null;
        }

        this.initialLoad = false;
      },
      immediate: true,
      deep: true,
    },
    "product.sageItemId": {
      handler (val) {
        if (val) {
          this.getSageItemInfo()
        }
      },
    },
    'changeSupplier': function (val) {
      if (this.suppliers.length && this.roles.length && this.product.inventory.managedBy.length) {
        this.managedBy = this.product.inventory.managedBy;
        this.assignedSupplier = this.suppliers.filter((val) => val.value === this.product.inventory.managedBy)[0].label
        this.assignedVisibleTo = this.roles.filter((val) => val.value === this.product.visibleTo)[0].label
      }
    },
    "assignedSupplier": function (newval, oldval) {
      if (this.product._id && oldval) {
        this.product.inventory.managedBy = newval ? newval.value : newval;
      } else if (!this.product._id) {
        this.product.inventory.managedBy = newval ? newval.value : newval;
      }
    },
    "assignedVisibleTo": function (newval, oldval) {
      // if (oldval) {
      //   this.product.visibleTo = newval ? newval.value : newval;
      // }
    },
    "changeData": function (val) {
      this.outOfStock = !this.product.isAvailable;
      if (this.product.categoryId.length && this.categories.length) {
        let index = 0;

        for (let cat of this.categories) {
          if (cat.ancestors && cat.ancestors.length > 0) {
            for (let catAncestor of cat.ancestors) {
              if (this.product.categoryId.indexOf(catAncestor._id) > -1) {
                this.showHideTick(`parent-categor-${index}`);
              }

              if (
                catAncestor.grandAncestors &&
                catAncestor.grandAncestors.length > 0
              ) {
                for (let catGrandAncestor of catAncestor.grandAncestors) {
                  if (
                    this.product.categoryId.indexOf(catGrandAncestor._id) > -1
                  ) {
                    this.showHideTick(`parent-categor-${index}`);
                  }
                }
              }
            }
          }

          index++;
        }
      }
    },
  }
}

</script>

<style>
.productOverviewClass .cke {
  border: 1px solid rgba(var(--vs-danger), 1) !important;
}
.custom-input {
  margin-bottom: 20px;
}

.custom-input label,
.custom-label {
  display: block;
  padding: 5px 0;
  font-size: 12px;
}

.stretch-width {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}

.custom-rule {
  border: solid 1px #28c76f;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.addMoreVariant {
  font-size: 13px;
  color: darkblue;
  cursor: pointer;
  float: right;
  margin-bottom: 10px;
  margin-right: 20px;
}

.removeVariant {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: darkred;
  font-size: 15px;
  font-weight: 900;
}

.to-uppercase input {
  text-transform: capitalize;
}

.non-accordion {
  margin-bottom: 20px;
  display: inline-block;
}

.child-category {
  margin-bottom: 20px;
}

.accordion-icon {
  float: right;
  /* background: #ddd; */
  padding: 4px;
  margin-left: 27px !important;
  cursor: pointer;
}

.isError .vs-select--input,
.isError .input-select,
.isError textarea {
  border: 1px solid rgba(234, 84, 85, 1) !important;
}

div.has-error {
  border: 1px solid rgba(234, 84, 85, 1) !important;
  border-radius: 5px;
  margin-bottom: 2px;
}

.isError {
  margin-bottom: 5px;
}

span.custom-error {
  color: rgba(234, 84, 85)
}

label.image-upload {
  border: 1px solid rgba(40, 199, 111, 1);
  color: rgba(40, 199, 111, 1);
  display: inline-block;
  border-radius: 5px;
  padding: 5px 15px;
}

label.replace-image {
  cursor: pointer;
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: solid 1px #a0a0a0;
}

.con-example-images {
  max-height: 500px;
  overflow: auto;
}

ul.product-images {
  display: block;
}

ul.product-images li {
  position: relative;
  background-size: cover;
  margin-top: 10px;
  height: 150px;
  width: 150px;
  border: solid 1px #f0f0f0;
}

ul.product-images li svg {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1000;
  color: rgb(40 199 111);
  cursor: pointer;
}

div.category-main-section {
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-gutter: stable;
}

#editor {
  margin: auto;
  width: 80%;
  height: 580px;
}

a.pdf-link {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-store-image:hover .pdf-rename {
  display: inline-block;
}

.pdf-rename {
  position: absolute;
  bottom: 0;
  z-index: 9;
  display: none;
  background-color: rgb(229 231 235) !important;
  border-radius: 0;
  padding: 6px !important;
  font-size: 0.90rem;
  color: grey !important;
}

.pdf-rename:hover {
  background-color: #30c9741a !important;
}

.pdf-filename {
  bottom: -1.2rem;
  width: 140px;
}

.vue-tags-input .ti-tag {
  background: transparent;
  border: 1px solid rgb(40 199 111);
  color: rgb(40 199 111) !important;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px !important;
  padding: 5px;
}

span.vs__selected {
  font-size: 14px !important;
}

.vs__dropdown-option.vs__dropdown-option--selected {
  background: #7d9986;
  color: #ffffff !important;
}
</style>
