import { render, staticRenderFns } from "./BundledItems.vue?vue&type=template&id=53cbf50c&"
import script from "./BundledItems.vue?vue&type=script&lang=js&"
export * from "./BundledItems.vue?vue&type=script&lang=js&"
import style0 from "./BundledItems.vue?vue&type=style&index=0&id=53cbf50c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports