<template>
  <div class="flex flex-col my-6 gap-5">
    <vs-checkbox v-model="productType">Fixed Quantity</vs-checkbox>
    <div>
      <label class="text-base">Select Items</label>
    </div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
        <AjaxSelect @onProductAdded="onProductAdded" />
      </vs-col>
    </vs-row>
    <vs-row
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
      v-for="(item, index) in bundledItems"
      :key="index"
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="0.5">
        <vx-tooltip text="Toggle this to make this product free in your bundle">
          <label :for="item._id" class="vs-input--label">Free</label>
          <vs-checkbox class="pt-2" :id="item._id" v-model="item.isFree"></vs-checkbox>
        </vx-tooltip>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
        <div class="w-full mr-5">
          <vs-input
            :success="!errors.first('basic.name') && item.name != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.name') ? true : false"
            v-validate="'required'"
            disabled
            name="productName"
            data-vv-as="Product Name"
            label="Product"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.name')"
            v-model="item.name"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="end" vs-w="2" v-if="productType || item.isFree">
        <div class="w-full mr-5">
          <vs-input
            :success="!errors.first(`basic.quantity${index}`) && item.quantity != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first(`basic.quantity${index}`) ? true : false"
            v-validate="'required|decimal:0|min_value:1'"
            :name="`quantity${index}`"
            data-vv-as="Quantity"
            label="Quantity"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first(`basic.quantity${index}`)"
            v-model="item.quantity"
          />
        </div>
        <vs-button
          v-if="item.isFree"
          color="danger"
          type="border"
          icon="remove"
          class="m-auto mt-4"
          @click="removeOption(index)"
        ></vs-button>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="end" vs-w="2" v-if="!item.isFree">
        <div class="w-full mr-5">
          <vs-input
            name="supplierCostPrice"
            label="Cost Price"
            class="w-full"
            v-model="item.supplierCostPrice"
          />
        </div>
        <vs-button
          color="danger"
          type="border"
          icon="remove"
          class="m-auto mt-4"
          @click="removeOption(index)"
        ></vs-button>
      </vs-col>
    </vs-row>
    <div v-if="!productType">
      <vs-input
        class="w-1/3"
        label="Bundle heading"
        v-model="productDetails.productTypeHeading"
        name="productTypeHeading"
  
          v-validate="'required'"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="errors.first(`productTypeHeading`) ? true : false"
          :danger-text="errors.first(`productTypeHeading`) ? `Field Bundle heading is required` : ''"
          :success="!errors.first(`productTypeHeading`) && productDetails.productTypeHeading != ''"
      />
      <vs-input
        class="w-1/3"
        label="Bundle description"
        v-model="productDetails.productTypeDescription"
      />
      <vs-input
        type="number"
        class="w-1/3"
        label="Minimum qty in bundle of all selected products"
        name="minQty"
        v-model="productDetails.minQty"
        val-icon-success="done"
        :danger="errors.first('productDetails.minQty') ? true : false"
        :danger-text="
          errors.first('productDetails.minQty') ? 'Minimum quantity is required' : ''
        "
        :success="!errors.first('productDetails.minQty') && productDetails.minQty != ''"
        data-vv-scope="productDetails"
        val-icon-danger="error"
        v-validate="{
          required: true,
          min_value: 1,
        }"
        data-vv-as="minQty"
      />
      <vs-input
        class="w-1/3 customFontColor"
        label="Minimum error message"
        v-model="productDetails.minErrorMessage"
      />
      <vs-input
        type="number"
        class="w-1/3"
        label="Maximum qty in bundle of all selected products"
        name="maxQty"
        v-model="productDetails.maxQty"
      />
      <vs-input
        class="w-1/3 customFontColor"
        label="Maximum error message"
        v-model="productDetails.maxErrorMessage"
      />
    </div>
  </div>
</template>

<script>
import AjaxSelect from "../../../webstore-discounts/AjaxSelect.vue";
export default {
  name: "BundledItems",
  inject: ["$validator"],
  props: ["bundledItems", "newBundleItems", "productDetails"],
  components: {
    AjaxSelect,
  },
  data() {
    return {
      product: {},
    };
  },
  computed: {
    productType: {
      get() {
        /**
         * Scenario for checking product type
         * 1. If product type is basic, then it must be the initial load of the 
         * bundle item component (since product type is not automatically updated
         * unless you press the save CTA). So, return true
         * 2. If product type is bundled-items, then return true
         * 3. If product type is not basic or bundled-items, then return false
         */
        if (this.productDetails.type === "basic") return true;
        return this.productDetails.type === "bundled-items";
      },
      set(isChecked) {
        const newType = isChecked ? "bundled-items" : "bundle";
        if (this.productDetails && this.productDetails.type !== newType) {
          this.productDetails.type = newType;
          this.$emit("updateProductType", this.productDetails);
        }
      },
    },
  },
  methods: {
    onProductAdded(product) {
      this.$emit("newBundleItems", product);
    },
    removeOption(index) {
      this.bundledItems.splice(index, 1);
    },
  },
};
</script>
<style>
.customFontColor {
  color: red;
}
</style>
